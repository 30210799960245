/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'

import Helmet from 'react-helmet'


const SEO = ({ title, description, ogImage }) => {

	const { site: { siteMetadata } } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
		`
	)

	const pageTitle = title ? `${title} | ${siteMetadata.title}` : siteMetadata.title

	const metaDescription = description || siteMetadata.description

	return (
		<Helmet
			title={pageTitle}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: pageTitle,
				},
				{
					property: `og:site_name`,
					content: pageTitle,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:image`,
                    content: ogImage ? ogImage : `https://yosukeurakami.com/img/og.png`,
				},
			]}
		/>
	)
}

export default SEO
